import requests from "./request";
// requests 为接口地址

import dtrequests from "./dtrequest"
// 


export const getgzproblem = () => requests({ url: 'client/article/124?PageIndex=1&PageSize=24' })

export const getgztypelist = () => requests({url: 'client/article/124/category?siteId=20'})

// 观众常见问题
export const getgzxx = (id) =>requests({url:`/client/article/124?CategoryId=${id}`})

// 展会新闻
export const getzhnews =(pageindex) =>requests({url:`/client/article/119?PageIndex=${pageindex}&PageSize=5`})
export const getzhxwfl = (pageIndex) =>requests({url:`/client/article/119?CategoryId=375&pageSize=5&pageIndex=${pageIndex}`})
// 119?pageSize=10&pageIndex=1
// 展商快讯
export const getzskxfl = (pageIndex) =>requests({url:`/client/article/119?CategoryId=377&pageSize=5&pageIndex=${pageIndex}`})

export const getzhxwdetails = (id) =>requests({url:`/client/article/show/${id}`})
// https://api.dtcms.polydt.com/article/category/119

// 展商常见问题
export const getzsxx = (id) =>requests({url:`/client/article/118?CategoryId=${id}`})

export const gethoteldata = ()=>requests({url:"client/article/117"})

export const gettarff = ()=>requests({url:"client/article/116"})

// 视频剪辑
export const getvideojj =(pageIndex)=>requests({url:`client/article/120?pageSize=6&pageIndex=${pageIndex}`})

// 观众注册
export const getregister =()=>requests({url:"client/article/130"})

// 合作媒体
export const getassociated  =()=>requests({url:"client/article/122?pageSize=99"})


// 城市概况
export const getcitylist =()=>requests({url:"client/article/131"})

// 展馆概述
export const getexhibitionhall =()=>requests({url:"client/article/133"})

// 签证信息 
export const getvisa =()=>requests({url:"client/article/132"})

// 为何参观
export const getwhcg = ()=> requests({url:"client/article/129"})

// 我们的展商
export const  getwmdzs = ()=>requests({url:"client/article/134?pageSize=32"})

// 电子杂志
export const getdzzz = (pageIndex)=>requests({url:`client/article/83?pageSize=6&pageIndex=${pageIndex}`})

// 展商手册
export const getxzzx =()=>requests({url:"client/article/123?CategoryId=364"})

//观众手册
export const getgzsc =()=>requests({url:"client/article/123?CategoryId=365"})

//精彩瞬间
export const getjcsj =()=>requests({url:"client/article/121?pageSize=99"})

//广告赞助手册
export const ggzzsc =()=>requests({url:"client/article/125"})

//现场时间
export const getxcsj =()=>requests({url:"client/article/113"})

//搭建服务
export const getdjfw =()=>requests({url:"client/article/128?CategoryId=383"})

//租赁服务
export const getzlfw =()=>requests({url:"client/article/128?CategoryId=384"})

//运输服务
export const getysfw =()=>requests({url:"client/article/128?CategoryId=385"})

//保险推荐
export const getbxtj =()=>requests({url:"client/article/128?CategoryId=386"})

//展商在线登记
export const getzszxdj =()=>requests({url:"client/article/127?pageSize=99"})

//展位图
export const getzwt =()=>requests({url:"client/article/112"})

//大会板块轮播部分
export const getdhbklb =()=>requests({url:"client/article/161"})
//验证码
export const verifycode =()=>requests({url:"verifycode"})
//登录
export const login =(data)=>requests({url:"auth/login",method:"post",data})
//注册
export const register =(data)=>requests({url:"account/member/register",method:"post",data})
//获取账号信息
export const account =()=>requests({url:"account/member"})
//参会协议
export const getchxy =()=>requests({url:"client/article/179?pageSize=10"})


export const getgklx =()=>dtrequests({url:`signup/api/DTCenter/HT_GetLabelListByGroup`,params:{
    group:"产品类型"
}})

// 获取上线的会议展商列表页面
export const getsxhyzs =(pageIndex)=>dtrequests({url:`signup/api/DTConferenceCompany/GetDTConferenceCompanyList`,params:{
    pageIndex,pageSize:8,ConferenceId:7
}})

export const getsearch=(text)=>dtrequests({url:`signup/api/DTConferenceCompany/GetDTConferenceCompanyList`,params:{
    pageSize:100,search:text
}})

export const getallzshy =()=>dtrequests({url:`signup/api/DTConference/GetDTConferenceList`})

// 根据id获取企业详情
export const getidqy =(CompanyId)=>dtrequests({url:`signup/api/DTCompany/HT_GetDTCompanyDetailById`,params:{CompanyId}})
// 根据id获取展商详情
export const getDTConferenceCompanyDetailById =(ConferenceCompanyId)=>dtrequests({url:`signup/api/DTConferenceCompany/HT_GetDTConferenceCompanyDetailById`,params:{ConferenceCompanyId}})

// 获取会议展商展品列表
export const getlistzp =(ConferenceId)=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/HT_GetDTConferenceCompanyExhibitsList`,params:{pageIndex:1,pageSize:5,ConferenceId}})

// 获取会议展商分类

export const getzstype =()=>dtrequests({url:`signup/api/DTConferenceCompany/GetDTConferenceCompanyTypeList`,params:{ConferenceId:7}})

// 获取会议展品分类

export const getzptype =()=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/GetDTConferenceCompanyExhibitsTypeList`,params:{ConferenceId:7}})

// 全部展品

export const getzpall =(pageIndex)=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/GetDTConferenceCompanyExhibitsAllList`,params:{pageIndex,pageSize:9}})

// 搜索展品
export const getsearchzp = (text)=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/GetDTConferenceCompanyExhibitsAllList`,params:{pageIndex:1,pageSize:100,search:text}})

export const getzpid =(ExhibitId)=>dtrequests({url:`signup/api/DTExhibits/GetDTExhibitsDetailById`,params:{ExhibitId}})

// 发布供需
export const getreleasegx =(data)=>dtrequests({url:`signup/api/H5FormList/InsertH5FormFields`,method:"post",data})

//供需列表
export const getgxlist =(pageIndex)=>dtrequests({url:`signup/api/H5ShareChannel/GetH5ShareChannelSignList`,params:{h5_share_channel_code:"1679b5",pageIndex,pageSize:5}})

//大会板块
export const getdhbk =()=>requests({url:`client/article/160`})

//展商分类
export const getfl =(pageIndex,TypeId)=>dtrequests({url:`signup/api/DTConferenceCompany/GetDTConferenceCompanyList`,params:{pageIndex,pageSize:'8',ConferenceId:'7',TypeId}})

//展品分类
export const getzpfl =(pageIndex,TypeId)=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/GetDTConferenceCompanyExhibitsAllList`,params:{pageIndex,pageSize:'9',TypeId}})

//展商名下展品
export const getzsmx = (ConferenceCompanyId)=>dtrequests({url:`signup/api/DTConferenceCompanyExhibits/GetDTExhibitsListByConferenceCompanyId`,params:{ConferenceCompanyId}})//展商名下展品
//获取门票列表
export const getTicketListWebsite = (uuId)=>dtrequests({url:`project-api/hzy/bk/ticket/listWebsite/`+uuId})
//获取WX支付二维码
// export const getWxPay = (total_fee)=>dtrequests({url:`project-api/hzy/wxPay/native?total_fee=`+total_fee})
// 线上购票接口
export const websiteOrder =(data)=>dtrequests({url:`project-api/hzy/mb/websiteOrder`,method:"post",data})
//查询支付成功
export const checkOrder = (order)=>dtrequests({url:`project-api/hzy/mb/websiteOrder/`+order})
// 支付生成订单
export const insertPageOrder =(data)=>dtrequests({url:`signup/api/H5FormList/HT_InsertPageOrder`,method:"post",data})
// 查询订单
export const getPageOrder =(params)=>dtrequests({url:`signup/api/H5FormList/HT_GetPageOrder?phone=`+params})